/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  font-size: 16px;
}

/**
 * Custom Notes build on Vision style:
 * icon: remove glyphicon and replace with font-awesome & simple-line-icons
 * helper: remove _utils.scss v3.x and repalce with _utils.scss v4.x on components.css file
 * hard-coded:
 *    _input-group.scss on reset rounded courner
 *      - add :not(.btn-pill):not(.btn-circle) selector for each button
 *    _navbar.scss wrap responsive .dropdown on .navbar-collapse
 *    mixins/_panel.scss removed line 14-17
 ========================================================================================= */

// replacer
@import "../../../globals/scss/fn";
@import "variables";
// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";


// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/responsive-utilities";